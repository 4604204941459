import React from "react";

const HealthCoachIcon: React.FC = () => <>
  <svg width="54" height="49" viewBox="0 0 54 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.5021 30.7733H29.4981C29.9397 30.7728 30.363 30.5972 30.6752 30.285C30.9874 29.9728 31.163 29.5495 31.1635 29.1079V24.9445H35.3268C35.7684 24.9441 36.1917 24.7685 36.5039 24.4563C36.8161 24.1441 36.9917 23.7207 36.9922 23.2792V18.2831C36.9917 17.8416 36.8161 17.4183 36.5039 17.106C36.1917 16.7938 35.7684 16.6182 35.3268 16.6178H31.1635V12.4544C31.163 12.0129 30.9874 11.5895 30.6752 11.2773C30.363 10.9651 29.9397 10.7895 29.4981 10.7891H24.5021C24.0605 10.7895 23.6372 10.9651 23.325 11.2773C23.0128 11.5895 22.8372 12.0129 22.8367 12.4544V16.6178H18.6733C18.2318 16.6182 17.8085 16.7938 17.4962 17.106C17.184 17.4183 17.0084 17.8416 17.008 18.2831V23.2792C17.0086 23.7207 17.1843 24.1439 17.4965 24.456C17.8087 24.7682 18.2319 24.9439 18.6733 24.9445H22.8367V29.1079C22.8374 29.5494 23.013 29.9726 23.3252 30.2848C23.6374 30.5969 24.0606 30.7726 24.5021 30.7733ZM35.3268 18.2831V23.2792H30.4981C29.9458 23.2792 29.4981 23.7269 29.4981 24.2792V29.1079H24.5021V24.2792C24.5021 23.7269 24.0543 23.2792 23.5021 23.2792H18.6733V18.2831H23.5021C24.0543 18.2831 24.5021 17.8354 24.5021 17.2831V12.4544H29.4981V17.2831C29.4981 17.8354 29.9458 18.2831 30.4981 18.2831H35.3268Z" fill="#73314F"/>
    <path d="M49.5 1.89062H4.68323C3.03273 1.89062 1.69223 3.22387 1.68328 4.87435L1.51502 35.9043C1.506 37.5675 2.85176 38.9205 4.51498 38.9205H44.9321C45.0832 38.9205 45.2262 38.9889 45.3212 39.1065L51.6109 46.8986C51.9071 47.2655 52.5 47.0561 52.5 46.5845V4.89062C52.5 3.23377 51.1569 1.89062 49.5 1.89062Z" stroke="#73314F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</>

export default HealthCoachIcon;
