import React from "react";

const Partner1Icon: React.FC = () => <>
  <svg width="105" height="106" viewBox="0 0 105 106" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M60.5104 5.75649C79.0472 6.44224 97.7427 14.7087 103.19 32.4404C108.429 49.4951 95.7847 64.5751 82.094 76.0151C63.1363 91.8562 41.141 114.899 20.2061 101.782C-2.9392 87.2794 -3.70739 54.0619 6.8634 28.8769C15.1951 9.02641 38.997 4.96062 60.5104 5.75649Z" fill="#F5F7F6"/>
  <path d="M39.1921 73V71.67L41.9221 70.83C43.6954 70.27 44.6054 69.1033 44.6521 67.33V34.01L38.8421 34.78V33.17C41.4554 32.7033 43.9988 32.0267 46.4721 31.14C48.9921 30.2067 51.3721 29.0867 53.6121 27.78L54.4521 28.48L54.1721 38.49V58.37C54.1721 59.5833 54.1721 61.0067 54.1721 62.64C54.2188 64.2267 54.2421 65.7667 54.2421 67.26C54.2421 69.08 55.1288 70.27 56.9021 70.83L59.7721 71.67V73H39.1921Z" fill="#275C61"/>
  </svg>
</>

export default Partner1Icon;
