import React from "react";

const HomeTestingIcon: React.FC = () => <>
  <svg width="54" height="43" viewBox="0 0 54 43" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M48.9956 0.785156H4.23098C1.89806 0.785156 0 2.68322 0 5.01614V38.4222C0 40.7552 1.89806 42.6532 4.23098 42.6532H48.9956C51.3285 42.6532 53.2266 40.7551 53.2266 38.4222V5.01614C53.2266 2.68322 51.3285 0.785156 48.9956 0.785156ZM51.7097 38.4222C51.7097 39.9188 50.4921 41.1363 48.9956 41.1363H4.23098C2.73435 41.1363 1.51685 39.9187 1.51685 38.4222V5.01614C1.51685 3.51962 2.73446 2.30201 4.23098 2.30201H48.9956C50.4921 2.30201 51.7097 3.51962 51.7097 5.01614V38.4222Z" fill="#73314F"/>
  <path d="M27.698 5.59766H14.4025C13.2716 5.59766 12.3516 6.51773 12.3516 7.64858C12.3516 8.77954 13.2716 9.69961 14.4025 9.69961H15.5578L15.5579 31.8017C15.5579 35.3555 17.8164 37.8375 21.0502 37.8375C24.2843 37.8375 26.5431 35.3555 26.5431 31.8017L26.5435 9.69961H27.6981C28.829 9.69961 29.749 8.77954 29.749 7.64858C29.7489 6.51773 28.8289 5.59766 27.698 5.59766ZM27.698 8.18276C25.3402 8.18287 27.7992 8.18266 23.0498 8.18298C22.6309 8.18308 22.2914 8.52267 22.2914 8.94146C22.2915 9.36035 22.6311 9.69983 23.0499 9.69983L25.0265 9.69972V14.8816H21.8831C21.4642 14.8816 21.1247 15.2212 21.1247 15.64C21.1247 16.0588 21.4642 16.3984 21.8831 16.3984H25.0265V19.3749H21.8831C21.4642 19.3749 21.1247 19.7144 21.1247 20.1333C21.1247 20.5522 21.4642 20.8917 21.8831 20.8917H25.0264L25.0263 23.8682H21.8829C21.4641 23.8682 21.1246 24.2078 21.1246 24.6266C21.1246 25.0455 21.4641 25.385 21.8829 25.385H25.0263L25.0262 28.3615H21.8829C21.4641 28.3615 21.1246 28.7011 21.1246 29.1199C21.1246 29.5386 21.4641 29.8782 21.8829 29.8782H25.0262V31.8015C25.0262 35.137 22.8842 36.3206 21.0501 36.3206C19.2162 36.3206 17.0747 35.137 17.0747 31.8015L17.0746 8.94103C17.0746 8.52224 16.735 8.18266 16.3162 8.18266H14.4025C14.1079 8.18266 13.8684 7.94303 13.8684 7.64848C13.8684 7.35392 14.108 7.1144 14.4025 7.1144H27.698C27.9925 7.1144 28.232 7.35403 28.232 7.64848C28.232 7.94324 27.9925 8.18276 27.698 8.18276Z" fill="#73314F"/>
  <path d="M8.93655 27.1474V6.35603C8.93655 5.93714 8.59696 5.59766 8.17817 5.59766C7.75928 5.59766 7.4198 5.93724 7.4198 6.35603V27.1448C7.08586 27.73 6.02974 29.6292 5.37856 31.3509C4.79829 32.8851 4.87451 34.578 5.58774 35.9956C6.17718 37.1669 7.07511 37.8197 8.11696 37.8352C8.13708 37.8368 8.1572 37.8375 8.17743 37.8375C8.19755 37.8375 8.21778 37.8368 8.2379 37.8352C9.27986 37.8198 10.1778 37.1669 10.7671 35.9956C11.4804 34.578 11.5566 32.8851 10.9763 31.3509C10.3261 29.6318 9.27209 27.7356 8.93655 27.1474ZM9.41197 35.3137C9.18309 35.7686 8.78911 36.3121 8.21171 36.3185C8.20138 36.3186 8.18978 36.3188 8.17732 36.3191C8.16476 36.3188 8.15327 36.3186 8.14294 36.3185C7.56554 36.3121 7.17145 35.7686 6.94257 35.3137C6.41968 34.2745 6.36666 33.0256 6.79716 31.8874C7.18529 30.8613 7.74363 29.7382 8.17732 28.9187C8.6108 29.7377 9.16883 30.8602 9.55738 31.8874C9.98798 33.0256 9.93497 34.2744 9.41197 35.3137Z" fill="#73314F"/>
  <path d="M47.4746 7.73828H34.3287C33.9098 7.73828 33.5703 8.07787 33.5703 8.49665C33.5703 8.91544 33.9098 9.25502 34.3287 9.25502H47.4747C47.8936 9.25502 48.2331 8.91544 48.2331 8.49665C48.2331 8.07787 47.8935 7.73828 47.4746 7.73828Z" fill="#73314F"/>
  <path d="M47.4746 12.1445H34.3287C33.9098 12.1445 33.5703 12.4841 33.5703 12.9029C33.5703 13.3218 33.9098 13.6613 34.3287 13.6613H47.4747C47.8936 13.6613 48.2331 13.3218 48.2331 12.9029C48.2331 12.4841 47.8935 12.1445 47.4746 12.1445Z" fill="#73314F"/>
  <path d="M47.4746 16.5547H34.3287C33.9098 16.5547 33.5703 16.8943 33.5703 17.3131C33.5703 17.732 33.9098 18.0714 34.3287 18.0714H47.4747C47.8936 18.0714 48.2331 17.732 48.2331 17.3131C48.2331 16.8943 47.8935 16.5547 47.4746 16.5547Z" fill="#73314F"/>
  <path d="M47.4746 20.9609H34.3287C33.9098 20.9609 33.5703 21.3005 33.5703 21.7193C33.5703 22.1382 33.9098 22.4777 34.3287 22.4777H47.4747C47.8936 22.4777 48.2331 22.1381 48.2331 21.7193C48.2331 21.3005 47.8935 20.9609 47.4746 20.9609Z" fill="#73314F"/>
  <path d="M47.4746 25.3672H34.3287C33.9098 25.3672 33.5703 25.7068 33.5703 26.1256C33.5703 26.5445 33.9098 26.8839 34.3287 26.8839H47.4747C47.8936 26.8839 48.2331 26.5443 48.2331 26.1256C48.2331 25.7068 47.8935 25.3672 47.4746 25.3672Z" fill="#73314F"/>
  <path d="M47.4746 29.7773H34.3287C33.9098 29.7773 33.5703 30.1169 33.5703 30.5357C33.5703 30.9546 33.9098 31.2941 34.3287 31.2941H47.4747C47.8936 31.2941 48.2331 30.9545 48.2331 30.5357C48.2331 30.1169 47.8935 29.7773 47.4746 29.7773Z" fill="#73314F"/>
  <path d="M40.9016 34.1836H34.3287C33.9098 34.1836 33.5703 34.5232 33.5703 34.942C33.5703 35.3609 33.9098 35.7003 34.3287 35.7003H40.9016C41.3205 35.7003 41.66 35.3608 41.66 34.942C41.66 34.5232 41.3205 34.1836 40.9016 34.1836Z" fill="#73314F"/>
  </svg>
</>

export default HomeTestingIcon;
