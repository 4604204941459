import React, { useState, useContext, useEffect } from "react";

import { mod } from "../utils";
import { Base, LimitMaxWidth, SectionHeading, ModalContext } from "../components/layout";
import DemoModal from "../components/modals/DemoModal";
import HeroCtaSection from "../components/page-sections/HeroCtaSection";
import WeServeSection from "../components/page-sections/WeServeSection";
import TestimonialsSection from "../components/page-sections/TestimonialsSection";
import DemoAndEmployerBannerSection from "../components/page-sections/DemoAndEmployerBannerSection";
import SEO from "../components/seo";

import TrackingIcon from "../assets/svg/icon-tracking";
import SelfManagementIcon from "../assets/svg/icon-self-management";
import HealthCoachIcon from "../assets/svg/icon-health-coach";
import HomeTestingIcon from "../assets/svg/icon-home-testing";
import Experts121Icon from "../assets/svg/icon-121-experts";
import ContentIcon from "../assets/svg/icon-content";

import Partner1Icon from "../assets/svg/employer-partner-1";
import Partner2Icon from "../assets/svg/employer-partner-2";
import Partner3Icon from "../assets/svg/employer-partner-3";
import Partner4Icon from "../assets/svg/employer-partner-4";
import CarouselSelectedIcon from "../assets/svg/icon-carousel-selected";
import CarouselNotSelectedIcon from "../assets/svg/icon-carousel-not-selected";
import heroWebp from "../assets/images/hero-employers.webp";

import benefitStrategyDeiPng from "../assets/images/benefits-strategy-dei.png";
import benefitStrategyTalentPng from "../assets/images/benefits-strategy-talent.png";
import benefitStrategyWellbeingPng from "../assets/images/benefits-strategy-wellbeing.png";
import benefitStrategyDeiAvif from "../assets/images/benefits-strategy-dei.avif";
import benefitStrategyTalentAvif from "../assets/images/benefits-strategy-talent.avif";
import benefitStrategyWellbeingAvif from "../assets/images/benefits-strategy-wellbeing.avif";
import benefitStrategyDeiWebp from "../assets/images/benefits-strategy-dei.webp";
import benefitStrategyTalentWebp from "../assets/images/benefits-strategy-talent.webp";
import benefitStrategyWellbeingWebp from "../assets/images/benefits-strategy-wellbeing.webp";

import trackingWithInfoPng from "../assets/images/feature-with-info-tracking.png";
import healthCoachWithInfoPng from "../assets/images/feature-with-info-health-coach.png";
import selfManagementWithInfoPng from "../assets/images/feature-with-info-self-management.png";
import homeTestingWithInfoPng from "../assets/images/feature-with-info-home-testing.png";
import experts121WithInfoPng from "../assets/images/feature-with-info-121-experts.png";
import contentWithInfoPng from "../assets/images/feature-with-info-content.png";
import trackingWithInfoAvif from "../assets/images/feature-with-info-tracking.avif";
import healthCoachWithInfoAvif from "../assets/images/feature-with-info-health-coach.avif";
import selfManagementWithInfoAvif from "../assets/images/feature-with-info-self-management.avif";
import homeTestingWithInfoAvif from "../assets/images/feature-with-info-home-testing.avif";
import experts121WithInfoAvif from "../assets/images/feature-with-info-121-experts.avif";
import contentWithInfoAvif from "../assets/images/feature-with-info-content.avif";
import trackingWithInfoWebp from "../assets/images/feature-with-info-tracking.webp";
import healthCoachWithInfoWebp from "../assets/images/feature-with-info-health-coach.webp";
import selfManagementWithInfoWebp from "../assets/images/feature-with-info-self-management.webp";
import homeTestingWithInfoWebp from "../assets/images/feature-with-info-home-testing.webp";
import experts121WithInfoWebp from "../assets/images/feature-with-info-121-experts.webp";
import contentWithInfoWebp from "../assets/images/feature-with-info-content.webp";

import trackingPng from "../assets/images/feature-tracking.png";
import healthCoachPng from "../assets/images/feature-health-coach.png";
import selfManagementPng from "../assets/images/feature-self-management.png";
import homeTestingPng from "../assets/images/feature-home-testing.png";
import experts121Png from "../assets/images/feature-121-experts.png";
import contentPng from "../assets/images/feature-content.png";
import trackingAvif from "../assets/images/feature-tracking.avif";
import healthCoachAvif from "../assets/images/feature-health-coach.avif";
import selfManagementAvif from "../assets/images/feature-self-management.avif";
import homeTestingAvif from "../assets/images/feature-home-testing.avif";
import experts121Avif from "../assets/images/feature-121-experts.avif";
import contentAvif from "../assets/images/feature-content.avif";
import trackingWebp from "../assets/images/feature-tracking.webp";
import healthCoachWebp from "../assets/images/feature-health-coach.webp";
import selfManagementWebp from "../assets/images/feature-self-management.webp";
import homeTestingWebp from "../assets/images/feature-home-testing.webp";
import experts121Webp from "../assets/images/feature-121-experts.webp";
import contentWebp from "../assets/images/feature-content.webp";

import genderIconsGridLargePng from "../assets/images/icons-gender-grid-large.png";
import genderIconsGridSmallPng from "../assets/images/icons-gender-grid-small.png";
import genderIconsGridLargeAvif from "../assets/images/icons-gender-grid-large.avif";
import genderIconsGridSmallAvif from "../assets/images/icons-gender-grid-small.avif";
import genderIconsGridLargeWebp from "../assets/images/icons-gender-grid-large.webp";
import genderIconsGridSmallWebp from "../assets/images/icons-gender-grid-small.webp";

const bottomBanner = <DemoAndEmployerBannerSection title="People are your asset." subtitle="So let's start a conversation." showEmployerReferral={false} />

export const Head = () => <SEO title="Syrona Health | Employee Benefits" />

const EmployersPage: React.FC = () => <Base bottomBanner={bottomBanner}><EmployersContent /></Base>


const EmployersContent: React.FC = () => {
  const { setModal } = useContext(ModalContext);

  const ctaAction = () => {
    setModal(<DemoModal/>);
  }

  return <>
    <HeroCtaSection 
      image={heroWebp}
      heading={<>Nurturing Inclusivity</>}
      subheading="by supporting diverse health needs."
      text="Support your workforce in the joyful or scary, big or small health journeys that are typically underserved."
      cta="Book a demo"
      ctaAction={ctaAction}
    />
    <WeServeSection />
    <BenefitStrategySection />
    <AppSection />
    <GenderInclusiveSection />
    <CalculatorSection />
    <TestimonialsSection />
    <PartnerSection />
  </>
}

const benefitStrategies = [
  {
    button: "Enhance wellbeing",
    title: "Enhance wellbeing through our life-stage app solution",
    text: "No matter the health concern, we support your employees through our complete wellness package. This includes self-management tools, access to health experts, medical products, and telemedicine.",
    png: benefitStrategyWellbeingPng,
    avif: benefitStrategyWellbeingAvif,
    webp: benefitStrategyWellbeingWebp,
  },
  {
    button: "Attract & Retain Talent",
    title: "Make your organisation stand out to new & existing employees",
    text: "Support your employees who may be experiencing challenges with Men's Health, Endometriosis, Fertility, Menopause, Cancer or other health concerns, which are not typically covered by health plans.",
    png: benefitStrategyTalentPng,
    avif: benefitStrategyTalentAvif,
    webp: benefitStrategyTalentWebp,
  },
  {
    button: "Make DE&I Actionable",
    title: "Implement solutions to help solve diversity, equity & inclusion",
    text: "Reach your DE&I goals by introducing the SORA app to help improve the health and wellbeing of those typically overlooked.",
    png: benefitStrategyDeiPng,
    avif: benefitStrategyDeiAvif,
    webp: benefitStrategyDeiWebp,
  },
]

const BenefitStrategySection: React.FC = () => {
  const [selectedIdx, setSelectedIdx] = useState(0)
  
  return <>
    <div className="pt-12 bg-syr-snd-lt">
      <LimitMaxWidth>
        <SectionHeading>
          Transform your benefits strategy with Syrona
        </SectionHeading>
        <div className="flex justify-center mt-4">
          <p className="md:w-8/12 text-b1 font-nun text-black text-center">
            Your employees may be experiencing challenges with Men's Health, Endometriosis, Fertility, Menopause, Cancer or other health concerns. 
          </p>
        </div>
        <div className="flex justify-center">
        <div className="md:hidden mt-8 mb-12 flex flex-col items-center">
          {benefitStrategies.map((s, idx) => <>
            <button className="max-lg:w-full" onClick={() => setSelectedIdx(idx)}>
              <hr className={`h-0.5 border-0 rounded ${selectedIdx == idx ? "bg-syr-snd" : "bg-[#979797]"}`} />
              <div className={`my-4 text-s1 font-nunb text-left ${selectedIdx == idx ? "text-syr-snd" : "text-[#979797]"}`}>{s.button}</div>
              <div className={`${selectedIdx == idx ? "" : "hidden"}`}>
                <p className="mt-4 text-b1 font-nun text-black text-left">
                  {benefitStrategies[selectedIdx].text}
                </p>
                <picture className="flex justify-center">
                  <source srcSet={benefitStrategies[selectedIdx].avif} type="image/avif" />
                  <source srcSet={benefitStrategies[selectedIdx].webp} type="image/webp" />
                  <img className={`${idx==benefitStrategies.length-1 ? "" : "mb-8"} mt-8 px-8 object-scale-down w-144`} src={benefitStrategies[selectedIdx].png} alt="" />
                </picture>
              </div>
            </button>
          </>)}
        </div>

        </div>
        <div className="max-md:hidden">
          <div className="mt-16 flex justify-center items-start space-x-12">
            {benefitStrategies.map((s, idx) => <>
              <button className="flex-auto" onClick={() => setSelectedIdx(idx)}>
                <hr className={`${selectedIdx == idx ? "h-0.5 border-0 rounded bg-syr-snd" : "bg-[#979797]"}`} />
                <div className={`mt-4 h-24 text-s1 font-nunb ${selectedIdx == idx ? "text-syr-snd" : "text-[#979797]"}`}>{s.button}</div>
              </button>
            </>)}
          </div>

          <div className="mt-8 flex space-x-8 justify-between">
            <picture className="w-5/12 max-w-sm self-end">
              <source srcSet={benefitStrategies[selectedIdx].avif} />
              <img className="object-scale-down object-bottom-left max-w-sm" src={benefitStrategies[selectedIdx].png} alt="" />
            </picture>
            <div className="w-6/12 self-start">
              <h3 className="text-h3 font-dm text-black leading-tight">
                {benefitStrategies[selectedIdx].title}
              </h3>
              <p className="mt-4 mb-8 text-b1 font-nun text-black">
                {benefitStrategies[selectedIdx].text}
              </p>
            </div>
          </div>
        </div>
      </LimitMaxWidth>
    </div>
  </>
}

const appFeatures = {
  tracking: {
    title: "Tracking",
    avifWithInfo: trackingWithInfoAvif,
    avif: trackingAvif,
    webpWithInfo: trackingWithInfoWebp,
    webp: trackingWebp,
    pngWithInfo: trackingWithInfoPng,
    png: trackingPng,
    icon: TrackingIcon,
    text: [ // NOTE: This text is also contained in the WithInfo images
      "They log their symptoms and daily activities to track their health and earn SORA coins.",
      "By tracking their health, they earn SORA coins that can be exchanged for discounts on health products or charity donations.",
    ],
  },
  healthCoach: {
    title: "Personal Health Coach",
    avifWithInfo: healthCoachWithInfoAvif,
    avif: healthCoachAvif,
    webpWithInfo: healthCoachWithInfoWebp,
    webp: healthCoachWebp,
    pngWithInfo: healthCoachWithInfoPng,
    png: healthCoachPng,
    icon: HealthCoachIcon,
    text: [ // NOTE: This text is also contained in the WithInfo images
      "They receive personal health coaching to give them guidance, keep them in control and provide them with the right tools to make informed decisions.",
      "We offer personalised support based on their needs, on their terms.",
    ],
  },
  selfManagement: {
    title: "Self-Management Plans",
    avifWithInfo: selfManagementWithInfoAvif,
    avif: selfManagementAvif,
    webpWithInfo: selfManagementWithInfoWebp,
    webp: selfManagementWebp,
    pngWithInfo: selfManagementWithInfoPng,
    png: selfManagementPng,
    icon: SelfManagementIcon,
    text: [ // NOTE: This text is also contained in the WithInfo images
      "These self-management plans optimise diet, exercise, sleep and mental health, giving them a sense of control over their own health.",
      "We offer personalised insights to empower them to become an active participant in their health journeys.",
    ],
  },
  homeTesting: {
    title: "Home-Testing Kits",
    avifWithInfo: homeTestingWithInfoAvif,
    avif: homeTestingAvif,
    webpWithInfo: homeTestingWithInfoWebp,
    webp: homeTestingWebp,
    pngWithInfo: homeTestingWithInfoPng,
    png: homeTestingPng,
    icon: HomeTestingIcon,
    text: [ // NOTE: This text is also contained in the WithInfo images
      "Home tests can provide a convenient, cost-effective, and time-saving alternative to traditional diagnostic tests.",
      "Our home-testing kits are processed by certified labs and offer privacy to patients.",
    ]
  },
  experts121: {
    title: "1-1 Health Experts",
    avifWithInfo: experts121WithInfoAvif,
    avif: experts121Avif,
    webpWithInfo: experts121WithInfoWebp,
    webp: experts121Webp,
    pngWithInfo: experts121WithInfoPng,
    png: experts121Png,
    icon: Experts121Icon,
    text: [ // NOTE: This text is also contained in the WithInfo images
      "Book consultations with our health specialists and access healthcare services without long waiting times or delays.",
      "Our interdisciplinary experts work with each other to treat them in a holistic way.",
    ],
  },
  content: {
    title: "Educational Content",
    avifWithInfo: contentWithInfoAvif,
    avif: contentAvif,
    webpWithInfo: contentWithInfoWebp,
    webp: contentWebp,
    pngWithInfo: contentWithInfoPng,
    png: contentPng,
    icon: ContentIcon,
    text: [ // NOTE: This text is also contained in the WithInfo images
      "Access medically reviewed content at your fingertips, be it blogs or videos, or simply enjoy having peer support.",
      "Our events give them an opportunity to share experiences and learn from experts about the latest developments.",
    ],
  },
}

const AppSection: React.FC = () => {
  const [selectedFeature, setSelectedFeature] = useState(appFeatures.tracking);

  return <>
    <div className="py-12">
      <LimitMaxWidth>
        <SectionHeading>
        How we support your team through our app, SORA
        </SectionHeading>
        <div className="mt-8 md:flex md:justify-between">
          <div className="md:w-4/12 max-md:flex max-md:items-center flex-col md:space-y-1 max-md:-space-y-0">
            {Object.values(appFeatures).map((m) => (
              <>
                <button className={`sm:w-96 max-sm:w-88 px-8 py-4 max-md:border-t-2 max-md:border-gray-300 md:hover:shadow-sym-2xl md:active:shadow-sym-2xl md:rounded-md ${m.title == selectedFeature.title ? "md:shadow-sym-2xl" : " max-md:last:border-b-2"}`} onClick={() => setSelectedFeature(m)}>
                  <div className="border-l-2 border-solid border-syr-main max-sm:text-m-h sm:text-subb font-nunb text-left indent-3">
                    {m.title}
                  </div>
                </button>
                {selectedFeature.title == m.title &&
                <div className="md:hidden pt-4 pb-12">
                  <div className="flex flex-col sm:w-96 max-sm:w-88 shadow-lg rounded-md">
                    <picture className="aspect-auto">
                      <source srcSet={selectedFeature.avif} type="image/avif" />
                      <source srcSet={selectedFeature.webp} type="image/webp" />
                      <img src={selectedFeature.png} alt={selectedFeature.title}/>
                    </picture>
                    <h3 className="pt-12 px-8 max-sm:text-m-h sm:text-2xl font-nunb text-black relative">{selectedFeature.title}<div className="p-4 absolute top-0 right-0"><selectedFeature.icon/></div></h3>
                    {selectedFeature.text.map(t => (
                      <p className="px-8 last:pb-8 max-sm:text-m-b sm:text-b2 font-nun text-black">{t}</p>
                    ))}
                  </div>
                </div>
                }
              </>
            ))}
          </div>
          <div className="max-md:hidden w-7/12 flex justify-center items-center">
            <div>
              <picture className="aspect-auto">
                <source srcSet={selectedFeature.avifWithInfo} type="image/avif" />
                <source srcSet={selectedFeature.webpWithInfo} type="image/webp" />
                <img src={selectedFeature.pngWithInfo} alt={selectedFeature.title}/>
              </picture>
            </div>
          </div>
        </div>
      </LimitMaxWidth>
    </div>
  </>
}

const GenderInclusiveSection: React.FC = () => <>
  <div className="bg-gradient-to-r from-syr-main to-syr-snd">
    <LimitMaxWidth>
      <div className="flex max-sm:flex-col overflow-visible w-screen">
        <div className="max-sm:h-5/6 sm:w-6/12 flex flex-col justify-center pr-16 py-12">
          <h3 className="max-sm:text-m-lg-t sm:text-h2 font-dm text-white">Supporting gender-inclusive life stages</h3>
          <p className="mt-8 max-sm:text-m-b sm:text-b1 font-nun text-white">
            We offer a smart and easy solution to give your people the help they need, on their terms.
          </p>
          <p className="max-sm:text-m-b sm:text-b1 font-nun text-white">
            Our tech is easy to navigate and modular. 
          </p>
          <p className="max-sm:text-m-b sm:text-b1 font-nun text-white">
            So every solution is bespoke to your organisation and its needs.
          </p>
        </div>
        <picture className="sm:hidden h-64 overflow-visible -ml-8">
          <source srcSet={genderIconsGridSmallAvif} type="image/avif" />
          <source srcSet={genderIconsGridSmallWebp} type="image/webp" />
          <img className="object-cover object-top h-full w-full" src={genderIconsGridSmallPng} alt=""/>
        </picture>
        <picture className="max-sm:hidden w-6/12 overflow-visible">
          <source srcSet={genderIconsGridLargeAvif} type="image/avif" />
          <source srcSet={genderIconsGridLargeWebp} type="image/webp" />
          <img className="object-cover object-left h-full w-full" src={genderIconsGridLargePng} alt=""/>
        </picture>
      </div>
    </LimitMaxWidth>
  </div>
</>

const CalculatorSection: React.FC = () => {
  const [numEmployees, setNumEmployees] = useState(5000)

  return <>
    <div className="bg-white py-12">
      <LimitMaxWidth>
        <SectionHeading>
          Find out how these underserved health conditions affect your workplace
        </SectionHeading>
        <p className="mx-12 mb-16 max-sm:text-m-b sm:text-b1 font-nun text-black text-center">
        Adjust the variables with an approximate value to see how much you can save and benefit with SORA by Syrona Health.
        </p>
        <div className="flex justify-center items-center">
        <div className="w-10/12 grid md:grid-cols-2 max-md:grid-cols-1 gap-4">
          <div className="md:col-span-2 bg-white rounded-lg shadow-sym-2xl md:h-48 px-12 py-8 flex flex-col justify-center items-center">
            <div className="w-full max-sm:text-m-h sm:text-b2 font-nunb uppercase text-left ">Your company number of employees</div>
            <div className="w-full sm:text-h2 max-sm:text-36 font-dm text-left">{numEmployees}</div>
            <div className="w-full">
              <input
                type="range"
                className="
                  appearance-none
                  cursor-pointer
                  rounded-full
                  bg-syr-main
                  focus:outline-none focus:ring-0 focus:shadow-none
                "
                id="customRange1"
                value={numEmployees}
                min={`${10}`}
                max={`${10000}`}
                onChange={event => setNumEmployees(parseInt(event.target.value))}
              />
            </div>
          </div>
          <div className="bg-white rounded-lg shadow-sym-2xl md:h-48 px-12 py-8 flex flex-col justify-center items-center">
            <div className="w-full sm:text-h2 max-sm:text-36 font-dm text-black text-left">
              {Math.floor(0.16 * numEmployees)}
            </div>
            <div className="w-full max-sm:text-m-h sm:text-lg font-nunsb text-black text-left">
              number of employees who require life-stage-specific support
            </div>
          </div>
          <div className="bg-white rounded-lg shadow-sym-2xl md:h-48 px-12 py-8 flex flex-col justify-center items-center">
            <div className="w-full sm:text-h2 max-sm:text-36 font-dm text-black text-left">
              {`£\u00A0${Math.floor(0.16 * numEmployees * 12957.25).toLocaleString()}`}
            </div>
            <div className="w-full max-sm:text-m-h sm:text-lg font-nunsb text-black text-left">
              total direct and indirect costs to the organisation
            </div>
          </div>
        </div>
        </div>
      </LimitMaxWidth>
    </div>
  </>
}

const partnerEntries = [
  {
    icon: Partner1Icon,
    title: "Integration",
    text: "From single sign-on to external APIs, we make it easy for users to access the app and the care they need.",
  },
  {
    icon: Partner2Icon,
    title: "Modular Platform",
    text: "Choose what parts of SORA you'd like to offer your organisation. It's fully modular to fit alongside any existing health plan.",
  },
  {
    icon: Partner3Icon,
    title: "Flexible Billing",
    text: "We offer a variety of billing options to suit your organisation, from small SMEs to large enterprises.",
  },
  {
    icon: Partner4Icon,
    title: "Analytics",
    text: "Access demographics, condition outcomes data, and usage on your personal SORA dashboard.",
  },
]

const PartnerSection: React.FC = () => {
  const [selected, setSelected] = useState(0);
  const [rotationTimeout, setRotationTimeout] = useState<NodeJS.Timeout|null>(null);
  const updateSelectedTimeout = 8 * 1000;

  const SelectedIcon = () => {
    const Icon = partnerEntries[selected].icon
    return <Icon/>
  }

  useEffect(() => {
    rotationTimeout && clearTimeout(rotationTimeout)
    const timeout = setTimeout(() => setSelected(mod(selected+1, partnerEntries.length)), updateSelectedTimeout);
    setRotationTimeout(timeout)
  }, [selected])

  return <>
    <div className="my-12 mx-8">
      <LimitMaxWidth>
        <SectionHeading>Your partner every step of the way</SectionHeading>
        <div className="mt-8 md:hidden flex justify-center items-center">
            <div className="w-72 flex flex-col justify-center items-center space-y-4 pb-8">
              <SelectedIcon/>
              <div className="text-h4 font-dm text-black text-center">{partnerEntries[selected].title}</div>
              <div className="text-s2 font-nun text-syr-dim-gray text-center">{partnerEntries[selected].text}</div>
            </div>
        </div>
        <div className="md:hidden flex justify-center space-x-4">
          {partnerEntries.map((_, idx) => <>
            <button onClick={() => setSelected(idx)}>
              {idx==selected ? <CarouselSelectedIcon/> : <CarouselNotSelectedIcon/>}
            </button>
          </>)}
        </div>
        <div className="mt-8 max-md:hidden flex justify-center">
        <div className="grid lg:grid-cols-4 md:max-lg:grid-cols-2 justify-center items-start justify-items-center gap-8">
          {partnerEntries.map(pe => <>
            <div className="w-72 flex flex-col items-center">
              <pe.icon/>
              <h4 className="text-h4 font-dm text-black text-center">{pe.title}</h4>
              <p className="max-sm:text-m-b sm:text-b2 font-nun text-black text-center">{pe.text}</p>
            </div>
          </>)}
        </div>
        </div>
      </LimitMaxWidth>
    </div>
  </>
}

export default EmployersPage;
