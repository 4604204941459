import React from "react";

const Partner4Icon: React.FC = () => <>
  <svg width="82" height="102" viewBox="0 0 82 102" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M30.0156 1.66406C59.5744 1.66406 81.6746 27.4255 81.6746 56.9843C81.6746 83.6572 56.6886 101.664 30.0156 101.664C9.10443 101.664 0.503906 77.8955 0.503906 56.9843C0.503906 32.4985 5.52984 1.66406 30.0156 1.66406Z" fill="#F5F7F6"/>
  <path d="M39.8032 73V61.59H21.3232V57.32L41.8332 27.99H48.3432V56.2H55.0632V61.59H48.3432V73H39.8032ZM25.0332 56.2H39.8032V35.06L25.0332 56.2Z" fill="#275C61"/>
  </svg>
</>

export default Partner4Icon;
