import React from "react";

const Partner2Icon: React.FC = () => <>
  <svg width="106" height="102" viewBox="0 0 106 102" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M51.4077 1.69159C66.5243 2.23919 78.3053 12.9487 87.9559 24.5968C97.9146 36.6168 107.881 50.6228 104.59 65.8815C101.267 81.287 86.8207 90.9984 72.3089 97.1449C58.7873 102.872 43.7843 103.411 30.4372 97.2885C16.7562 91.0126 5.87209 79.5711 2.18696 64.9773C-1.6015 49.9744 1.8812 34.0373 11.3811 21.8228C21.0275 9.41986 35.7053 1.12277 51.4077 1.69159Z" fill="#F5F7F6"/>
  <path d="M37.7379 40.17L38.2279 30.3C39.6279 29.5067 41.3779 28.8533 43.4779 28.34C45.6245 27.78 47.9812 27.5 50.5479 27.5C55.9612 27.5 59.8812 28.6433 62.3079 30.93C64.7345 33.2167 65.9479 36.0867 65.9479 39.54C65.9479 42.76 64.5012 45.8867 61.6079 48.92C58.7145 51.9533 54.5612 55.4533 49.1479 59.42C48.1212 60.1667 46.9312 61.0767 45.5779 62.15C44.2712 63.1767 42.9879 64.2267 41.7279 65.3H67.2079V73H36.6879V67.33C40.1412 63.97 43.3379 60.7733 46.2779 57.74C49.6845 54.24 52.2045 51.1367 53.8379 48.43C55.4712 45.6767 56.2879 42.7833 56.2879 39.75C56.2879 36.67 55.7045 34.1967 54.5379 32.33C53.3712 30.4633 51.6679 29.53 49.4279 29.53C47.9345 29.53 46.6045 29.88 45.4379 30.58C44.3179 31.28 43.3845 32.47 42.6379 34.15L39.9079 40.17H37.7379Z" fill="#275C61"/>
  </svg>
</>

export default Partner2Icon
