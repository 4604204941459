import React from "react";

const Experts121Icon: React.FC = () => <>
  <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M53.2812 39.5312H1.71875V12.8906C1.72011 11.7514 2.17326 10.6593 2.97878 9.85378C3.78431 9.04826 4.87644 8.59511 6.01562 8.59375H16.3281C16.556 8.59375 16.7746 8.68429 16.9358 8.84546C17.097 9.00662 17.1875 9.2252 17.1875 9.45312C17.1875 9.68105 17.097 9.89963 16.9358 10.0608C16.7746 10.222 16.556 10.3125 16.3281 10.3125H6.01562C5.33186 10.3125 4.67611 10.5841 4.19262 11.0676C3.70912 11.5511 3.4375 12.2069 3.4375 12.8906V37.8125H51.5625V12.8906C51.5625 12.2069 51.2909 11.5511 50.8074 11.0676C50.3239 10.5841 49.6681 10.3125 48.9844 10.3125H39.4866C39.2586 10.3125 39.0401 10.222 38.8789 10.0608C38.7177 9.89963 38.6272 9.68105 38.6272 9.45312C38.6272 9.2252 38.7177 9.00662 38.8789 8.84546C39.0401 8.68429 39.2586 8.59375 39.4866 8.59375H48.9844C50.1236 8.59511 51.2157 9.04826 52.0212 9.85378C52.8267 10.6593 53.2799 11.7514 53.2812 12.8906V39.5312Z" fill="#73314F"/>
  <path d="M40.0661 53.2852H14.9414L20.0977 42.9727H34.9098L40.0661 53.2852ZM17.7223 51.5664H37.2852L33.8477 44.6914H21.1598L17.7223 51.5664Z" fill="#73314F"/>
  <path d="M42.1094 53.2812H12.8906C12.6627 53.2812 12.4441 53.1907 12.283 53.0295C12.1218 52.8684 12.0312 52.6498 12.0312 52.4219C12.0312 52.194 12.1218 51.9754 12.283 51.8142C12.4441 51.653 12.6627 51.5625 12.8906 51.5625H42.1094C42.3373 51.5625 42.5559 51.653 42.717 51.8142C42.8782 51.9754 42.9688 52.194 42.9688 52.4219C42.9688 52.6498 42.8782 52.8684 42.717 53.0295C42.5559 53.1907 42.3373 53.2812 42.1094 53.2812Z" fill="#73314F"/>
  <path d="M48.9844 44.6875H6.01562C4.87644 44.6861 3.78431 44.233 2.97878 43.4275C2.17326 42.6219 1.72011 41.5298 1.71875 40.3906V37.8125H53.2812V40.3906C53.2799 41.5298 52.8267 42.6219 52.0212 43.4275C51.2157 44.233 50.1236 44.6861 48.9844 44.6875ZM3.4375 39.5312V40.3906C3.4375 41.0744 3.70912 41.7301 4.19262 42.2136C4.67611 42.6971 5.33186 42.9688 6.01562 42.9688H48.9844C49.6681 42.9688 50.3239 42.6971 50.8074 42.2136C51.2909 41.7301 51.5625 41.0744 51.5625 40.3906V39.5312H3.4375Z" fill="#73314F"/>
  <path d="M21.4854 26.6432C21.3009 26.6434 21.1212 26.5842 20.9729 26.4744C20.8246 26.3646 20.7156 26.2099 20.662 26.0334C20.6084 25.8568 20.6131 25.6676 20.6754 25.4939C20.7376 25.3202 20.8541 25.1711 21.0076 25.0688C21.7527 24.5721 22.3525 23.4506 22.7444 21.8246C22.7688 21.7129 22.8153 21.6071 22.8811 21.5135C22.9469 21.4199 23.0307 21.3404 23.1276 21.2796C23.2246 21.2189 23.3327 21.1781 23.4456 21.1597C23.5585 21.1412 23.6739 21.1455 23.7852 21.1723C23.8964 21.1991 24.0012 21.2478 24.0933 21.3156C24.1855 21.3834 24.2632 21.4689 24.3218 21.5671C24.3805 21.6654 24.419 21.7743 24.435 21.8876C24.451 22.0009 24.4442 22.1162 24.415 22.2268C23.914 24.31 23.0882 25.7503 21.9615 26.4988C21.8205 26.5928 21.6549 26.643 21.4854 26.6432Z" fill="#73314F"/>
  <path d="M33.5126 26.6449C33.3449 26.6444 33.1811 26.5945 33.0416 26.5014C32.8698 26.3888 31.3727 25.3154 30.5709 22.2526C30.5424 22.1434 30.5356 22.0297 30.551 21.9179C30.5664 21.8061 30.6037 21.6984 30.6607 21.601C30.7177 21.5036 30.7934 21.4184 30.8833 21.3502C30.9733 21.2821 31.0758 21.2323 31.1849 21.2037C31.2941 21.1752 31.4079 21.1684 31.5197 21.1838C31.6315 21.1992 31.7392 21.2365 31.8366 21.2935C31.9339 21.3505 32.0192 21.4262 32.0873 21.5161C32.1555 21.6061 32.2053 21.7086 32.2338 21.8178C32.4615 23.0663 33.0828 24.2091 34.0067 25.0791C34.1576 25.1836 34.271 25.3337 34.3304 25.5073C34.3898 25.681 34.3921 25.8691 34.3369 26.0441C34.2816 26.2192 34.1718 26.3719 34.0235 26.48C33.8751 26.5881 33.6961 26.6459 33.5126 26.6449Z" fill="#73314F"/>
  <path d="M29.2188 23.2048H25.7812C23.5028 23.2023 21.3184 22.296 19.7073 20.6849C18.0962 19.0739 17.19 16.8895 17.1875 14.611V13.8049C17.1875 13.577 17.278 13.3584 17.4392 13.1973C17.6004 13.0361 17.819 12.9455 18.0469 12.9455C18.2748 12.9455 18.4934 13.0361 18.6545 13.1973C18.8157 13.3584 18.9062 13.577 18.9062 13.8049V14.611C18.9083 16.4338 19.6333 18.1812 20.9222 19.4701C22.211 20.759 23.9585 21.484 25.7812 21.486H29.2188C31.0415 21.484 32.789 20.759 34.0778 19.4701C35.3667 18.1812 36.0917 16.4338 36.0938 14.611V13.2266C36.0938 12.9986 36.1843 12.7801 36.3455 12.6189C36.5066 12.4577 36.7252 12.3672 36.9531 12.3672C37.181 12.3672 37.3996 12.4577 37.5608 12.6189C37.722 12.7801 37.8125 12.9986 37.8125 13.2266V14.611C37.81 16.8895 36.9038 19.0739 35.2927 20.6849C33.6816 22.296 31.4972 23.2023 29.2188 23.2048Z" fill="#73314F"/>
  <path d="M18.0373 14.669C17.0284 14.669 16.3599 14.6174 16.255 14.608C16.0565 14.5915 15.8699 14.5065 15.7271 14.3676C15.5843 14.2287 15.4941 14.0446 15.4721 13.8466C14.9445 9.09852 15.9302 5.67735 18.4009 3.68016C22.415 0.431726 28.8964 2.18055 30.1459 2.56126C33.6281 2.0611 36.3034 2.72196 38.1003 4.51891C41.1734 7.59204 40.4129 12.8033 40.3785 13.0267C40.355 13.1834 40.2886 13.3306 40.1867 13.4519C40.0848 13.5732 39.9513 13.664 39.801 13.7142C35.3941 15.1855 31.6619 12.2481 30.1408 10.7769C27.0565 14.2075 21.0417 14.669 18.0373 14.669ZM17.1118 12.9305C19.427 13.0001 26.742 12.9073 29.3613 8.97735C29.4354 8.86605 29.5344 8.77356 29.6505 8.70725C29.7666 8.64094 29.8966 8.60263 30.0301 8.59537C30.1636 8.58811 30.297 8.6121 30.4196 8.66543C30.5422 8.71877 30.6506 8.79997 30.7363 8.90258C30.7741 8.94727 34.4256 13.2313 38.7337 12.2258C38.8196 10.9445 38.8351 7.67798 36.8809 5.72805C35.4577 4.31008 33.2139 3.82454 30.2078 4.28774C30.0733 4.30806 29.9358 4.29658 29.8065 4.25423C29.7395 4.23274 23.1034 2.08087 19.4785 5.01907C17.6154 6.53329 16.8188 9.18876 17.1118 12.9305Z" fill="#73314F"/>
  <path d="M43.8281 39.5312H11.1719V35.2344C11.1748 32.5002 12.2623 29.8789 14.1956 27.9456C16.1289 26.0123 18.7502 24.9248 21.4844 24.9219H21.7594L27.0016 28.6653C27.1474 28.7697 27.3223 28.8259 27.5017 28.8259C27.6811 28.8259 27.856 28.7697 28.0019 28.6653L33.2406 24.9219H33.5156C36.2498 24.9248 38.8711 26.0123 40.8044 27.9456C42.7377 29.8789 43.8252 32.5002 43.8281 35.2344V39.5312ZM12.8906 37.8125H42.1094V35.2344C42.1079 33.0018 41.2386 30.8572 39.6853 29.2536C38.1319 27.6501 36.016 26.7131 33.7846 26.6406L28.9987 30.0592C28.5615 30.3715 28.0377 30.5393 27.5004 30.5393C26.9632 30.5393 26.4393 30.3715 26.0021 30.0592L21.2154 26.6398C18.9838 26.7122 16.8678 27.6493 15.3144 29.2531C13.761 30.8568 12.8919 33.0016 12.8906 35.2344V37.8125Z" fill="#73314F"/>
  <path d="M19.7656 31.7969C19.5377 31.7969 19.3191 31.7063 19.158 31.5452C18.9968 31.384 18.9062 31.1654 18.9062 30.9375V26.6406C18.9062 26.4127 18.9968 26.1941 19.158 26.033C19.3191 25.8718 19.5377 25.7812 19.7656 25.7812C19.9935 25.7812 20.2121 25.8718 20.3733 26.033C20.5345 26.1941 20.625 26.4127 20.625 26.6406V30.9375C20.625 31.1654 20.5345 31.384 20.3733 31.5452C20.2121 31.7063 19.9935 31.7969 19.7656 31.7969Z" fill="#73314F"/>
  <path d="M22.3438 36.0938C22.1158 36.0938 21.8972 36.0032 21.7361 35.842C21.5749 35.6809 21.4844 35.4623 21.4844 35.2344V33.5156C21.4844 33.0598 21.3033 32.6226 20.981 32.3003C20.6586 31.978 20.2215 31.7969 19.7656 31.7969C19.3098 31.7969 18.8726 31.978 18.5503 32.3003C18.228 32.6226 18.0469 33.0598 18.0469 33.5156V35.2344C18.0469 35.4623 17.9563 35.6809 17.7952 35.842C17.634 36.0032 17.4154 36.0938 17.1875 36.0938C16.9596 36.0938 16.741 36.0032 16.5798 35.842C16.4187 35.6809 16.3281 35.4623 16.3281 35.2344V33.5156C16.3281 32.6039 16.6903 31.7296 17.3349 31.0849C17.9796 30.4403 18.8539 30.0781 19.7656 30.0781C20.6773 30.0781 21.5516 30.4403 22.1963 31.0849C22.841 31.7296 23.2031 32.6039 23.2031 33.5156V35.2344C23.2031 35.4623 23.1126 35.6809 22.9514 35.842C22.7903 36.0032 22.5717 36.0938 22.3438 36.0938Z" fill="#73314F"/>
  <path d="M35.2344 31.7969C35.0065 31.7969 34.7879 31.7063 34.6267 31.5452C34.4655 31.384 34.375 31.1654 34.375 30.9375V26.6406C34.375 26.4127 34.4655 26.1941 34.6267 26.033C34.7879 25.8718 35.0065 25.7812 35.2344 25.7812C35.4623 25.7812 35.6809 25.8718 35.842 26.033C36.0032 26.1941 36.0938 26.4127 36.0938 26.6406V30.9375C36.0938 31.1654 36.0032 31.384 35.842 31.5452C35.6809 31.7063 35.4623 31.7969 35.2344 31.7969Z" fill="#73314F"/>
  <path d="M35.2344 36.9531C34.5545 36.9531 33.8899 36.7515 33.3246 36.3738C32.7593 35.9961 32.3187 35.4592 32.0585 34.8311C31.7984 34.203 31.7303 33.5118 31.8629 32.845C31.9956 32.1782 32.323 31.5657 32.8037 31.0849C33.2844 30.6042 33.8969 30.2768 34.5638 30.1442C35.2306 30.0115 35.9217 30.0796 36.5499 30.3398C37.178 30.6 37.7148 31.0406 38.0926 31.6059C38.4703 32.1711 38.6719 32.8358 38.6719 33.5156C38.6719 34.4273 38.3097 35.3016 37.6651 35.9463C37.0204 36.591 36.1461 36.9531 35.2344 36.9531ZM35.2344 31.7969C34.8944 31.7969 34.5621 31.8977 34.2795 32.0865C33.9968 32.2754 33.7765 32.5438 33.6465 32.8579C33.5164 33.172 33.4823 33.5175 33.5487 33.8509C33.615 34.1843 33.7787 34.4906 34.019 34.731C34.2594 34.9713 34.5657 35.135 34.8991 35.2014C35.2325 35.2677 35.5781 35.2336 35.8921 35.1035C36.2062 34.9735 36.4746 34.7532 36.6635 34.4705C36.8523 34.1879 36.9531 33.8556 36.9531 33.5156C36.9531 33.0598 36.772 32.6226 36.4497 32.3003C36.1274 31.978 35.6902 31.7969 35.2344 31.7969Z" fill="#73314F"/>
  </svg>
</>

export default Experts121Icon;
