import React from "react";

import { buttonStyle } from "../styles";
import { LimitMaxWidth } from "../layout";

type SectionProps = {
  image: string
} & ContentProps

const HeroCtaSection: React.FC<SectionProps> = ({ image, ...contentProps}) => <>
  <div className="bg-gradient-to-r from-syr-main to-syr-snd">
    <div className="bg-cover bg-no-repeat bg-right-top" style={{backgroundImage: `url(${image})` }}>
      <div className="bg-cover bg-gradient-to-r from-syr-nero">
        <HeroCtaContent {...contentProps}/>
      </div>
    </div>
  </div>
</>

export default HeroCtaSection;

type ContentProps = {
  heading: React.ReactElement;
  subheading?: string;
  text: string;
  cta: string;
  ctaAction: any; 
}

const HeroCtaContent: React.FC<ContentProps> = ({ heading, subheading, text, cta, ctaAction}) => <>
  <LimitMaxWidth>
    <div className="lg:w-6/12 md:max-lg:w-6/12 sm:max-md:w-10/12 flex flex-col first:pt-24 last:pb-32 py-0">
      <div className="max-sm:text-m-lg-t sm:text-h2 font-dm text-white">{heading}</div>
      {subheading && <div className="max-sm:text-28 sm:text-38 font-dmi text-white">{subheading}</div>}
      <div className="max-sm:text-m-body sm:text-b1 font-nun text-white">{text}</div>
      <div className="mt-4">
        <button
          className={`
            ${buttonStyle}
            bg-white
            text-black
          `}
          onClick={ctaAction}
        >
          {cta}
        </button>
      </div>
    </div>
  </LimitMaxWidth>
</>
