import React from "react";

const Partner3Icon: React.FC = () => <>
  <svg width="95" height="106" viewBox="0 0 95 106" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M46.1708 1.45821C58.704 3.6092 65.8367 15.4774 74.4967 24.7894C82.6407 33.5466 93.8456 41.162 94.211 53.1153C94.582 65.2535 84.9614 74.7328 76.2829 83.2273C67.7306 91.5983 58.0663 98.8294 46.1708 100.137C32.8318 101.603 17.7701 100.482 8.62162 90.6644C-0.37173 81.0135 2.78 66.3 2.35295 53.1153C1.90155 39.179 -2.91193 23.6856 6.10775 13.0522C15.4566 2.03088 31.9267 -0.986417 46.1708 1.45821Z" fill="#F5F7F6"/>
  <path d="M44.848 78.812C41.8613 78.812 39.3647 78.4854 37.358 77.832C35.3513 77.1787 33.3913 76.3387 31.478 75.312L32.178 66.212H34.208L36.658 71.672C38.2447 75.1254 40.858 76.852 44.498 76.852C46.6913 76.852 48.488 75.9887 49.888 74.262C51.288 72.5354 51.988 69.6654 51.988 65.652C51.988 62.0587 51.358 59.492 50.098 57.952C48.838 56.412 46.8313 55.642 44.078 55.642H39.948V53.542H43.658C46.1313 53.542 47.9747 52.8187 49.188 51.372C50.4013 49.8787 51.008 47.3587 51.008 43.812C51.008 40.452 50.448 38.0487 49.328 36.602C48.208 35.1554 46.7847 34.432 45.058 34.432C43.3313 34.432 41.9313 34.8754 40.858 35.762C39.8313 36.6487 38.9913 37.8387 38.338 39.332L35.678 45.002L33.578 44.932L34.278 34.712C35.7713 34.012 37.4513 33.452 39.318 33.032C41.1847 32.5654 43.2613 32.332 45.548 32.332C50.3547 32.332 54.018 33.382 56.538 35.482C59.058 37.582 60.318 40.242 60.318 43.462C60.318 45.7487 59.3847 47.9187 57.518 49.972C55.698 52.0254 52.8513 53.4954 48.978 54.382C53.4113 54.802 56.6313 56.0854 58.638 58.232C60.6913 60.332 61.718 62.8754 61.718 65.862C61.718 68.3354 61.0413 70.552 59.688 72.512C58.3347 74.472 56.398 76.012 53.878 77.132C51.358 78.252 48.348 78.812 44.848 78.812Z" fill="#275C61"/>
  </svg>
</>

export default Partner3Icon;
